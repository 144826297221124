import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"
import { useMutation } from "react-query"
import { fetchChangeQtyProduct, fetchRemoveProduct } from "../../api/cartAPI"
import { getAvailableStatus, getIsAvailable } from "../../components/Available"
import { ProductWithChildType } from "../../components/Products/types"
import { shareInMessages } from "../../components/ShareCopiText/util"
import { setDiscount } from "../../store/reducers/cartSlice"
import { COOKIE_HOST_NAME } from "../../utils/constants"
import { getTranslationProperties } from "../../utils/helpers"
import { useCart } from "../cart/cart"
import { useClipboardCopy } from "../clipboardCopy"
import { useCompare } from "../compare/useCompare"
import { useFavorites } from "../favorites"
import { usePrevious } from "../previous"
import { useAppDispatch } from "../redux"
import {
  cookies,
  DEFAULT_QTY,
  getImages,
  getIsKit,
  getPath,
  initialState,
} from "./helpers"
import { reducer } from "./reducer"
import {
  ProductActionTypes,
  UseProductReturnType,
  UseProductType,
} from "./types"

export const useProduct: UseProductType = ({
  product: productInput,
  options: { parentKit, isSaveOnRemove = true, onAddToCart, initQty } = {},
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const initialCountRef = useRef<number | null>(initQty || null)
  const isLog = useRef<boolean>(false)
  const dispatchRedux = useAppDispatch()

  const [
    {
      product,
      count,
      counter,
      unit,
      isInit,
      units,
      inCart,
      currentSpecification,
      totalQty,
      isRemoved,
      isAnimate,
      isCountError,
      unitMeasure,
      uuid,
      stores,
      storesAvailable,
    },
    dispatch,
  ] = useReducer(reducer, {
    ...initialState,
    count: initialCountRef.current,
    counter: initialCountRef.current,
    totalQty: productInput?.total_qty || 0,
  })

  const {
    removeAll: removeAllFromFavorites,
    isFetching: isFetchingFavorites,
    remove: removeFromFavorites,
    add: addToFavorites,
    isFavorites,
  } = useFavorites(uuid || undefined)

  //===//
  // compare start
  //===//
  const {
    isFetching: isFetchingCompare,
    remove: removeFromCompare,
    add: addToCompare,
    isCompare,
  } = useCompare(uuid || undefined)

  const toggleCompare = useCallback(() => {
    const uuidCategory = product?.categories?.[0]

    if (uuid === null) {
      return
    } else if (isCompare) {
      removeFromCompare(uuid, uuidCategory ?? "")
    } else {
      addToCompare({
        productUUID: [uuid],
        categoryUUID: uuidCategory ?? "",
      })
    }
  }, [addToCompare, isCompare, removeFromCompare, uuid])
  //===//
  // compare end
  //===//

  const {
    removeProductInFetching,
    addProductInFetching,
    removeSpecification,
    updateSpecification,
    updateTotalCost,
    specification,
    updateToken,
    token,
  } = useCart()

  const { isCopied: isCopiedPath, handleCopyClick: handleCopyPath } =
    useClipboardCopy()
  const { isCopied: isCopiedCode, handleCopyClick: handleCopyCode } =
    useClipboardCopy()
  const { isCopied: isCopiedArticle, handleCopyClick: handleCopyArticle } =
    useClipboardCopy()

  const [isAdded, setIsAdded] = useState(false)

  const latestUnitRef = useRef<number | null>(unit)
  const isCalledCountChangedRef = useRef<boolean>(false)
  const latestIsInit = useRef(false)
  const latestCountInCart = useRef<number | null>(null)
  const latestCount = useRef<number | null>(null)

  const prevProduct = usePrevious<ProductWithChildType>(productInput)

  const properties = useMemo(
    () => getTranslationProperties(productInput?.properties || []),
    [productInput?.properties],
  )
  const priceCalc = useMemo(
    () => (product?.price || 0) * (count || 1),
    [count, product?.price],
  )

  const path = useMemo(
    () => getPath(productInput?.alias),
    [productInput?.alias],
  )

  const isVariative = useMemo(
    () =>
      !!productInput?.variation?.selected?.uuid &&
      !!productInput?.variation?.model &&
      productInput?.variation?.model.length > 0,
    [productInput?.variation?.model, productInput?.variation?.selected?.uuid],
  )

  const isAvailable = useMemo(
    () => getIsAvailable(productInput?.total_qty),
    [productInput?.total_qty],
  )

  const availableStatus = useMemo(
    () =>
      getAvailableStatus({
        quantity: totalQty,
        maxQty: productInput?.max_qty || 0,
        minQty: productInput?.min_qty || 0,
      }),
    [productInput?.max_qty, productInput?.min_qty, totalQty],
  )

  const setInCartHandle = useCallback((value) => {
    dispatch({
      type: ProductActionTypes.setInCart,
      payload: value,
    })
  }, [])

  const setIsCountErrorHandle = useCallback((value) => {
    dispatch({
      type: ProductActionTypes.setIsCountError,
      payload: value,
    })
  }, [])

  const makedAnalogs = useMemo(
    () =>
      [
        ...(product?.analogs?.fast || []),
        ...(product?.analogs?.other || []),
      ].filter((uuid) => uuid !== undefined),
    [product?.analogs?.fast, product?.analogs?.other],
  )

  const makedAnalogsIds = useMemo(
    () => makedAnalogs.map((item) => item.uuid || ""),
    [makedAnalogs],
  )

  const [isTriggerAddToCart, setIsTriggerAddToCart] = useState(false)

  const updateDiscount = useCallback(
    (discount: number) => {
      dispatchRedux(setDiscount(discount))
    },
    [dispatchRedux],
  )

  const copyPath = useCallback(() => {
    if (path === null) {
      return
    }
    const cHost = cookies.get(COOKIE_HOST_NAME)
    if (!!cHost) {
      handleCopyPath(`https://${cHost}${path}`)
    }
  }, [handleCopyPath, path])

  const copyCode = useCallback(() => {
    if (productInput?.code === undefined) {
      return
    }
    handleCopyCode(productInput.code)
  }, [handleCopyCode, productInput?.code])

  const copyArticle = useCallback(() => {
    if (productInput?.article === undefined) {
      return
    }
    handleCopyArticle(productInput.article)
  }, [handleCopyArticle, productInput?.article])

  const share = useCallback(() => {
    shareInMessages({
      title: product?.name ?? "",
      url: path ?? "",
      text: product?.description ?? "",
    })
  }, [path, product?.description, product?.name])

  const toggleFavorite = useCallback(() => {
    if (uuid === null) return

    if (isFavorites) {
      removeFromFavorites(uuid)
    } else {
      addToFavorites([uuid])
    }
  }, [addToFavorites, isFavorites, removeFromFavorites, uuid])

  const removeAllFavorite = useCallback(() => {
    removeAllFromFavorites()
  }, [removeAllFromFavorites])

  const { mutate: changeQtyMutate } = useMutation(fetchChangeQtyProduct, {
    onSuccess: (response, variables) => {
      if (uuid === null) {
        return
      }
      if (token === null) {
        updateToken(response.cart)
      }
      const initialTotalQty = productInput?.total_qty || 0

      // если на момент добавления в корзину изменилось наличие на складах
      // обновляем в стейте
      if (
        variables.quantity > response.addedQty &&
        variables.quantity <= initialTotalQty
      ) {
        dispatch({
          type: ProductActionTypes.setTotalQty,
          payload: {
            total: response.addedQty,
          },
        })
        setCurrentUnit(response.addedQty)
      } else {
        if (totalQty !== initialTotalQty) {
          dispatch({
            type: ProductActionTypes.setTotalQty,
            payload: {
              total: initialTotalQty,
            },
          })
        }
      }

      if (
        (!inCart || isRemoved) &&
        ((product?.kit || []).length > 0 ||
          (product?.kit_parents || []).length > 0)
      ) {
        setIsAdded(true)
      }

      updateSpecification({
        uuid: uuid,
        quantity: response.addedQty !== 0 ? response.addedQty : undefined,
        isRemoved: false,
        kit: product?.kit || [],
      })

      // логика для товара, который является частью комплекта
      // если добавили такой товар, но не передали его parent
      // значит в корзине товар должен добавиться как самостоятельный отдельный item
      if (variables.parent === undefined && !!parentKit) {
        updateSpecification({
          uuid: uuid,
          isAnimate: true,
        })

        const { current: countInCart } = latestCountInCart
        if (countInCart !== null) {
          dispatch({
            type: ProductActionTypes.setCount,
            payload: {
              count: countInCart,
              parentKit: parentKit,
            },
          })
          dispatch({
            type: ProductActionTypes.setCounter,
            payload: {
              counter: countInCart,
            },
          })
        }
      }
      setIsFetching(false)
      updateTotalCost(response.total_cost)
      updateDiscount(response.discount || 0)
      removeProductInFetching(uuid || "")
    },
    onError: () => {
      setIsFetching(false)
      removeProductInFetching(uuid || "")
    },
    onMutate: () => {
      setIsFetching(true)
      addProductInFetching(uuid || "")
    },
    mutationKey: "changeQtyProduct",
  })
  const { mutate: removeProductMutate } = useMutation(fetchRemoveProduct, {
    onSuccess: (response) => {
      if (uuid === null) {
        return
      }
      dispatch({
        type: ProductActionTypes.setIsRemoved,
        payload: true,
      })
      setIsAdded(false)

      if (isSaveOnRemove) {
        updateSpecification({
          uuid: uuid,
          isRemoved: true,
          parent: parentKit,
        })
      } else {
        if (uuid !== undefined) {
          removeSpecification(uuid)
        }
      }

      setIsFetching(false)
      updateTotalCost(response.total_cost)
      updateDiscount(response.discount || 0)
      removeProductInFetching(uuid || "")
    },
    onError: () => {
      dispatch({
        type: ProductActionTypes.setIsRemoved,
        payload: false,
      })
      dispatch({
        type: ProductActionTypes.setCount,
        payload: {
          count: unit,
          parentKit,
        },
      })
      removeProductInFetching(uuid || "")
      setIsFetching(false)
    },
    onMutate: () => {
      setIsFetching(true)
      addProductInFetching(uuid || "")
    },
    mutationKey: "removeProduct",
  })

  const addToCart = useCallback(() => {
    const { current: countInCart } = latestCountInCart
    const { current: count } = latestCount

    if (!uuid || count === null || isFetching || !totalQty) {
      return
    }

    if (isLog.current) {
      console.log("[addToCart]")
    }

    let quantityCalc = count
    if (parentKit !== undefined) {
      if (isLog.current) {
        console.log("[addToCart] parentKit ", parentKit)
      }
      if (countInCart !== null && quantityCalc > countInCart) {
        quantityCalc = count - countInCart
      }
    }

    if (isLog.current) {
      console.log(
        "[addToCart] quantityCalc ",
        quantityCalc,
        " count ",
        count,
        " countInCart ",
        countInCart,
      )
    }

    if (quantityCalc !== countInCart || count !== countInCart || isRemoved) {
      const qty = quantityCalc > 0 ? quantityCalc : count

      if (qty > totalQty) {
        dispatch({
          type: ProductActionTypes.setIsCountError,
          payload: true,
        })
        return
      }

      changeQtyMutate(
        {
          product: uuid,
          quantity: qty,
          cart: token || undefined,
        },
        onAddToCart,
      )
    }
  }, [uuid, parentKit, isRemoved, totalQty, changeQtyMutate, token, isFetching])

  const removeFromCart = useCallback(() => {
    if (!uuid || !token) {
      return
    }
    removeProductMutate({
      cart: token,
      product: uuid,
      parent: parentKit,
    })
  }, [parentKit, token, uuid, removeProductMutate])

  const setCurrentCount = useCallback(
    (value: number, withError?: boolean) => {
      if (isLog.current) {
        console.log("[setCurrentCount] value ", value)
      }
      let _withError = withError

      const { current: latestUnit } = latestUnitRef
      const { current: isCalled } = isCalledCountChangedRef
      if (!isCalled) {
        if (
          latestUnit !== null &&
          latestUnit > totalQty &&
          value === latestUnit
        ) {
          isCalledCountChangedRef.current = true
          _withError = false
        }
      }

      dispatch({
        type: ProductActionTypes.setCounter,
        payload: {
          counter: value,
        },
      })

      if (inCart) {
        if (value < 1) {
          removeFromCart()
          return
        }
      }

      dispatch({
        type: ProductActionTypes.setCount,
        payload: {
          count: value,
          isLog: isLog.current,
          witError: _withError,
          parentKit,
        },
      })
    },
    [inCart, removeFromCart, totalQty, parentKit],
  )

  const setCurrentUnit = useCallback(
    (value: number) => {
      if (isLog.current) {
        console.log("[setCurrentUnit] value ", value)
      }

      dispatch({
        type: ProductActionTypes.setUnit,
        payload: {
          unit: value,
        },
      })

      const { current: unit } = latestUnitRef
      const { current: initialCount } = initialCountRef
      const valueCount = initialCount || value
      if (valueCount === unit || unit === -1) {
        return
      }
      setCurrentCount(valueCount, true)
    },
    [setCurrentCount],
  )

  const initialCartHandle = useCallback(
    ({ qty }: { qty: number }) => {
      if (isLog.current) {
        console.log("[initialCartHandle] qty ", qty)
      }
      latestCountInCart.current = qty
      setCurrentCount(qty)
    },
    [setCurrentCount],
  )

  const initialDefaultHandle = useCallback(() => {
    if (isLog.current) {
      console.log("[initialDefaultHandle] ", units)
    }
    if (units === null) {
      return
    }
    latestCountInCart.current = null
    setCurrentUnit(+(units[0]?.value || DEFAULT_QTY))
  }, [units, setCurrentUnit])

  const initialHandle = useCallback(() => {
    // не была получена корзина
    if (currentSpecification === null) {
      return
    }

    if (isLog.current) {
      console.log("[initialHandle] currentSpecification ", currentSpecification)
    }

    // если quantity undefined - это значит добавлен образец, но не товар
    if (
      currentSpecification?.quantity !== undefined &&
      currentSpecification.quantity > 0 &&
      !currentSpecification.isRemoved
    ) {
      initialCartHandle({
        qty: currentSpecification.quantity,
      })
    } else {
      initialDefaultHandle()
    }

    latestIsInit.current = true
    dispatch({
      type: ProductActionTypes.setIsInit,
      payload: true,
    })
  }, [currentSpecification, initialCartHandle, initialDefaultHandle])

  const updateCurrentCount = useCallback(
    (value: number) => {
      const { current: isInit } = latestIsInit
      if (!isInit) {
        return
      }
      if (isLog.current) {
        console.log("updateCurrentCount ext value ", value)
      }
      setCurrentCount(value, true)
    },
    [setCurrentCount],
  )

  const updateCurrentUnit = useCallback(
    (value: number) => {
      const { current: isInit } = latestIsInit
      if (!isInit) {
        return
      }
      if (isLog.current) {
        console.log("updateCurrentUnit ext value ", value)
      }
      setCurrentUnit(value)
    },
    [setCurrentUnit],
  )

  useEffect(() => {
    latestCount.current = count
    setIsTriggerAddToCart(true)
  }, [count])

  useEffect(() => {
    if (isLog.current) {
      console.log("[effect] unit changed ", unit)
    }
    latestUnitRef.current = unit
  }, [unit])

  useEffect(() => {
    if (prevProduct?.uuid === productInput?.uuid) {
      return
    }
    if (isLog.current) {
      console.log("productInput ", productInput)
    }
    dispatch({
      type: ProductActionTypes.setProduct,
      payload: {
        product: {
          ...productInput,
        },
        parentKit: parentKit,
      },
    })
  }, [prevProduct?.uuid, productInput, parentKit])

  useEffect(() => {
    dispatch({
      type: ProductActionTypes.setCurrentSpecification,
      payload: {
        uuid: uuid || null,
        specification,
        parentKit,
        isLog: isLog.current,
      },
    })
  }, [specification, uuid, parentKit])

  useEffect(() => {
    dispatch({
      type: ProductActionTypes.setTotalQty,
      payload: {
        sample: !!currentSpecification?.isSampleRemoved
          ? 0
          : currentSpecification?.sample || 0,
        total: product?.total_qty || 0,
      },
    })
  }, [
    currentSpecification?.isSampleRemoved,
    currentSpecification?.sample,
    product?.total_qty,
    uuid,
  ])

  useEffect(() => {
    initialHandle()
  }, [initialHandle, uuid])

  useEffect(() => {
    if (isLog.current) {
      console.log("[effect] count changed ", count)
    }
  }, [count])

  useEffect(() => {
    if (!isInit || !isTriggerAddToCart || isFetching) {
      return
    }
    if (isLog.current) {
      console.log(
        "!isInit || !isTriggerAddToCart || isFetching ",
        !isInit,
        " ",
        !isTriggerAddToCart,
        " ",
        isFetching,
      )
    }
    if (inCart && !isRemoved) {
      setIsTriggerAddToCart(false)
      addToCart()
    }
  }, [addToCart, inCart, isInit, isRemoved, isFetching, isTriggerAddToCart])

  useEffect(() => {
    if (uuid === null) {
      return
    }
    if (isAnimate) {
      setTimeout(() => {
        updateSpecification({
          uuid: uuid,
          isAnimate: false,
        })
        dispatch({
          type: ProductActionTypes.setIsAnimate,
          payload: false,
        })
      }, 3000)
    }
  }, [isAnimate, updateSpecification, uuid])

  useEffect(() => {
    if (isCountError) {
      setTimeout(() => {
        dispatch({
          type: ProductActionTypes.setIsCountError,
          payload: false,
        })
      }, 3000)
    }
  }, [isCountError])

  useEffect(() => {
    dispatch({
      type: ProductActionTypes.setStores,
      payload: {
        stores: productInput?.stores,
        maxQty: productInput?.max_qty || 0,
        minQty: productInput?.min_qty || 0,
      },
    })
  }, [productInput?.max_qty, productInput?.min_qty, productInput?.stores])

  return {
    availableStatus: availableStatus,
    //start compare
    isFetchingCompare: isFetchingCompare,
    toggleCompare,
    isCompare,
    //start favorites
    isFetchingFavorites: isFetchingFavorites,
    removeAllFavorite,
    toggleFavorite,
    isFavorites,
    path: path,
    units: units || [],
    updateCurrentCount: updateCurrentCount,
    updateCurrentUnit: updateCurrentUnit,
    totalQty: totalQty,
    priceCalculate: priceCalc,
    currentUnit: unit,
    currentCount: count,
    unitMeasure: unitMeasure,
    isFetching: isFetching,
    inCart: inCart,
    addToCart,
    removeFromCart,
    isRemoved,
    properties: properties,
    setInCart: setInCartHandle,
    setIsFetching: setIsFetching,
    isAnimate,
    storesAvailable: storesAvailable,
    stores: stores,
    storesQty: storesAvailable.length,
    isCopiedPath: isCopiedPath,
    copyPath: copyPath,
    share: share,
    uuid: productInput?.uuid || "",
    alias: productInput?.alias || null,
    totalQtyBase: productInput?.total_qty || 0,
    priceUnit: productInput?.price || 0,
    child: productInput?.child,
    kit: productInput?.kit || [],
    categories: productInput?.categories || [],
    description: productInput?.description || null,
    fastQty: productInput?.fast_qty || 0,
    name: productInput?.name || null,
    images: getImages(productInput.images),
    isKit: getIsKit(productInput?.kit),
    kitParents: productInput?.kit_parents || [],
    isBestseller: !!productInput?.is_bestseller,
    isNew: !!productInput?.is_new,
    code: productInput?.code,
    article: productInput?.article || null,
    copyArticle: copyArticle,
    isCopiedArticle: isCopiedArticle,
    variation: productInput?.variation,
    isAllowSample: productInput.allow_sample,
    counter: counter,
    isCopiedCode: isCopiedCode,
    copyCode: copyCode,
    isCountError,
    setIsCountError: setIsCountErrorHandle,
    isInit: isInit && uuid !== null,
    isAdded,
    setIsAdded,
    isAvailable: isAvailable,
    currentSpecification: currentSpecification,
    isVariative: isVariative,
    analogs: makedAnalogs,
    analogsIds: makedAnalogsIds,
    updateDiscount,
  } as const as UseProductReturnType
}

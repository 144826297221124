import { FC, memo, useCallback, useEffect, useMemo, useState } from "react"
import { DEFAULT_UNIT_MEASURE } from "../../../../hooks/product/helpers"
import { IconPoint } from "../../../../styles/utils/Utils"
import { colors } from "../../../../styles/utils/vars"
import { RadioGroupItemsType } from "../../../../types/types"
import { Icon } from "../../../ui/Icon"
import { CustomRadioGroup } from "../../../ui/Radio/CustomRadioGroup"
import { Typography } from "../../../ui/Typography/Typography"

type UnitControlPropsType = {
  units: RadioGroupItemsType[]
  setCurrentUnit?: (value: number) => void
  totalQty: number
  unitMeasure?: string | null
  unitValueActive?: number
  indexDefault?: number
  isInitProduct?: boolean
  isForceUpdate?: boolean
}

const UnitControlInner: FC<UnitControlPropsType> = memo(
  ({
    units,
    setCurrentUnit,
    unitMeasure,
    totalQty,
    unitValueActive,
    isInitProduct,
  }) => {
    const foundIndex = useMemo(
      () => units.findIndex((u) => u.value === unitValueActive),
      [unitValueActive, units],
    )

    const onChangeRadioHandler = useCallback(
      (value?: string | number) => {
        if (
          value !== undefined &&
          setCurrentUnit !== undefined &&
          isInitProduct
        ) {
          setCurrentUnit(+value)
        }
      },
      [setCurrentUnit, isInitProduct],
    )

    return (
      <>
        {units.length > 0 ? (
          <CustomRadioGroup
            variant={"tabs"}
            indexDefault={foundIndex}
            items={units}
            ariaLabel={"packageToggle"}
            indexCurrent={foundIndex < 0 ? 0 : foundIndex}
            onChange={onChangeRadioHandler}
            disabled={!totalQty}
            isForceUpdate={unitValueActive !== undefined && unitValueActive < 0}
          />
        ) : (
          <IconPoint>
            <Icon
              NameIcon={"Layers"}
              fill={colors.brand.yellow}
              size={"small"}
            />
            <Typography variant={"span"} color={colors.grayDark}>
              {totalQty} {unitMeasure || DEFAULT_UNIT_MEASURE}
            </Typography>
          </IconPoint>
        )}
      </>
    )
  },
)

UnitControlInner.displayName = "UnitControlInner"

export const UnitControl: FC<UnitControlPropsType> = ({
  isInitProduct,
  ...rest
}) => {
  const [isInit, setIsInit] = useState(!!isInitProduct)

  useEffect(() => {
    setIsInit(!!isInitProduct)
    return () => {
      setIsInit(false)
    }
  }, [isInitProduct])

  return <>{isInit && <UnitControlInner {...rest} isInitProduct={isInit} />}</>
}

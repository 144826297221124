import { FC } from "react"
import {
  SContent,
  SField,
  SImage,
  SProductCatalog,
  StyledSkeleton,
} from "./StyledSkeleton"

export const SkeletonProduct: FC = () => {
  return (
    <>
      <StyledSkeleton>
        <SProductCatalog>
          <SImage />
          <SContent>
            <SField />
            <SField
              style={{
                width: "70%",
              }}
            />
            <SField
              style={{
                width: "80%",
              }}
            />
          </SContent>
        </SProductCatalog>
      </StyledSkeleton>
    </>
  )
}

import { AvailableFnType } from "./types"

export const getIsAvailable = (total_qty?: number) => !!total_qty

/*
*  Если количество меньше min_qty, тогда писать "мало".
* Если количество больше max_qty, тогда писать "много".
* Иначе писать "достаточно".
Такие данные возвращаю в товаре, списке товаров и в каталоге.
Для товара мин и макс сравнивать с  total_qty,
* для склада - с quantity.
* */

export const getAvailableStatus: AvailableFnType = ({
  minQty,
  maxQty,
  quantity,
}) =>
  quantity === 0
    ? "not"
    : quantity <= minQty
    ? "few"
    : quantity >= maxQty
    ? "many"
    : "enough"

import type { FC } from "react"
import { StyledCharacter, Title, Value } from "./StyledProperties"

type CharacterPropsType = {
  title?: string
  value?: string
}

const Property: FC<CharacterPropsType> = ({ title, value }) => {
  return (
    <>
      {title && value && (
        <StyledCharacter>
          <Title>{title}</Title>
          <Value>{value}</Value>
        </StyledCharacter>
      )}
    </>
  )
}

export default Property

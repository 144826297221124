import { FC, memo, useContext } from "react"
import { ROUTES, VIEW_PRODUCTS_LIST } from "../../../../utils/constants"
import { Button } from "../../../ui/Button/Button"
import { ButtonGroup } from "../../../ui/Button/StyledButton"
import { ModalContext } from "../../../ui/Modals/Modal"
import { Typography } from "../../../ui/Typography/Typography"
import { Product } from "../../Catalog/Product"
import { Products } from "../../Products"
import { ProductType } from "../../types"
import { KitPartProducts } from "../KitPart/KitPartProducts"

export type AfterAddedToCartKitPropsType = {
  product: ProductType
  companions?: ProductType[]
  kitProducts?: ProductType[]
  analogs?: ProductType[]
  kitParents?: string[]
  onClose?: () => void
}

export const AfterAddedToCartKit: FC<AfterAddedToCartKitPropsType> = memo(
  ({ product, companions, kitProducts, analogs, kitParents, onClose }) => {
    const modalContext = useContext(ModalContext)

    return (
      <>
        <Product product={product} view={VIEW_PRODUCTS_LIST} isPreview />
        <br />
        <ButtonGroup>
          <Button variant={"filled"} as={"a"} href={ROUTES.checkout}>
            Оформить заказ
          </Button>
          <Button
            variant={"outline"}
            onClick={() => {
              modalContext?.hide()
              if (onClose !== undefined) {
                onClose()
              }
            }}
          >
            Продолжить покупки
          </Button>
        </ButtonGroup>

        {kitProducts !== undefined && kitProducts.length > 0 && (
          <>
            <br />
            <Typography variant={"h5"}>Состав этого комплекта</Typography>
            <Products view={VIEW_PRODUCTS_LIST}>
              {kitProducts.map((p) => (
                <Product
                  product={p}
                  view={VIEW_PRODUCTS_LIST}
                  key={p.uuid || ""}
                />
              ))}
            </Products>
          </>
        )}

        {!!kitParents?.length && (
          <>
            <br />
            <Typography variant={"h5"}>
              Комплекты, в которые входит товар
            </Typography>
            <KitPartProducts ids={kitParents} slidesPerViewStart={3} />
          </>
        )}

        {companions !== undefined && companions.length > 0 ? (
          <>
            <br />
            <Typography variant={"h5"}>Вам также могут пригодиться</Typography>
            <Products view={VIEW_PRODUCTS_LIST}>
              {companions.map((p) => (
                <Product
                  product={p}
                  view={VIEW_PRODUCTS_LIST}
                  key={p.uuid || ""}
                />
              ))}
            </Products>
          </>
        ) : (
          <>
            {analogs !== undefined && analogs.length > 0 && (
              <>
                <br />
                <Typography variant={"h5"}>Могут Вас заинтересовать</Typography>
                <Products view={VIEW_PRODUCTS_LIST}>
                  {analogs.map((p) => (
                    <Product
                      product={p}
                      view={VIEW_PRODUCTS_LIST}
                      key={p.uuid || ""}
                    />
                  ))}
                </Products>
              </>
            )}
          </>
        )}
      </>
    )
  },
)

AfterAddedToCartKit.displayName = "AfterAddedToCartKit"

import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { cx } from "@linaria/core";
import { useQuery } from "react-query";
import type { ProductCatalogType } from "../../../../../contracts/contracts";
import { fetchProductsList } from "../../../../api/productsAPI";
import { useProduct } from "../../../../hooks/product/product";
import { useWindowSize } from "../../../../hooks/windowSize";
import { getBreakpointVal } from "../../../../styles/utils/Utils";
import { breakpoints } from "../../../../styles/utils/vars";
import { CURRENCY, VIEW_PRODUCTS_GRID } from "../../../../utils/constants";
import { sortProductsWeightRule } from "../../../../utils/helpers";
import Available from "../../../Available/Available";
import { AddToCartControl, AfterAddedToCartKitPropsType, Badges, Counter, Price, StoresAvailablePropsType, Title, UnitControl } from "../../parts";
import { CodeArticleInfo } from "../../parts/CodeArticleInfo";
import { ProductWrapper } from "../../ProductWrapper";
import { cssProductPreview, ProductContainer, ProductDetailInfo } from "../../StyledProduct";
import type { ViewProductsType } from "../../types";
import { ButtonIconGroup } from "../ButtonIconGroup";
import { ImageTitleCard } from "../ImageTitleCard";
import { Modal } from "components/ui/Modals/Modal";
const ListInfo = dynamic(((() => import("../../parts").then(mod => mod.StoresAvailable)) as DynamicOptions<StoresAvailablePropsType>));
const AfterAddedToCartKit = dynamic(((() => import("../../parts").then(mod => mod.AfterAddedToCartKit)) as DynamicOptions<AfterAddedToCartKitPropsType>), {
  ssr: false
});
export type pageType = "any" | "favorite" | "compare";
export type CatalogProductPropsType = {
  product: ProductCatalogType;
  disabledHover?: boolean;
  view: ViewProductsType;
  isMiniCard?: boolean;
  isPreview?: boolean;
  typePage?: pageType;
  sortWeight?: number;
  categories?: string;
  isShared?: boolean;
  index?: number;
};
const Product: FC<CatalogProductPropsType> = memo(({
  disabledHover = false,
  isMiniCard = false,
  isPreview = false,
  isShared = false,
  sortWeight,
  categories,
  typePage,
  product,
  view,
  ...props
}) => {
  const {
    availableStatus,
    units,
    updateCurrentCount,
    updateCurrentUnit,
    // compare
    isCompare,
    toggleCompare,
    isFetchingCompare,
    //favorite
    isFetchingFavorites,
    toggleFavorite,
    isFavorites,
    totalQty,
    priceCalculate,
    currentUnit,
    isFetching,
    addToCart,
    inCart,
    isRemoved,
    storesQty,
    storesAvailable,
    counter,
    currentCount,
    isCountError: isCountProductError,
    setIsCountError,
    isInit: isInitProduct,
    isAdded,
    kitParents,
    setIsAdded,
    uuid,
    priceUnit,
    kit,
    unitMeasure,
    isKit,
    isBestseller,
    alias,
    isNew,
    name,
    images,
    path,
    isAvailable,
    analogs: analogsConcated,
    analogsIds,
    code,
    article
  } = useProduct({
    product: product,
    options: {
      isSaveOnRemove: false,
      initQty: product.initQty
    }
  });
  const [isHover, setIsHover] = useState<boolean>(false);
  const productRef = useRef<HTMLDivElement>(null);
  const isInitEntity = useMemo(() => {
    return isInitProduct && uuid !== null;
  }, [isInitProduct, uuid]);
  const companionsIds = useMemo(() => (product?.companions || []).map(item => item.uuid || ""), [product?.companions]);
  const {
    data: kitProducts
  } = useQuery(["productAnalogs", isAdded, kit], () => fetchProductsList({
    uuids: kit.join(",")
  }), {
    enabled: isAdded && kit.length > 0
  });
  const {
    data: analogsData
  } = useQuery(["productAnalogs", isAdded, analogsIds], () => fetchProductsList({
    uuids: analogsIds.join(",")
  }), {
    enabled: isAdded && analogsIds.length > 0
  });
  const {
    data: companionsData
  } = useQuery(["companions", isAdded, companionsIds], () => fetchProductsList({
    uuids: companionsIds.join(",")
  }), {
    enabled: isAdded && companionsIds.length > 0
  });
  const analogs = useMemo(() => !!analogsData ? sortProductsWeightRule({
    products: analogsData,
    rules: analogsConcated
  }) : [], [analogsData, analogsConcated]);
  const companions = useMemo(() => !!companionsData ? sortProductsWeightRule({
    products: companionsData,
    rules: product?.companions
  }) : [], [companionsData, product?.companions]);
  const {
    width
  } = useWindowSize();
  const isLessMd = width !== undefined && width <= getBreakpointVal(breakpoints.md);
  const onMouseEventHandle = useCallback((value: boolean) => {
    if (!isInitEntity || view !== VIEW_PRODUCTS_GRID || disabledHover || isLessMd) {
      setIsHover(false);
      return;
    }
    setIsHover(value);
  }, [disabledHover, isInitEntity, isLessMd, view]);
  const onMouseEnterHandle = () => {
    onMouseEventHandle(true);
  };
  const onMouseLeaveHandle = useCallback(() => {
    onMouseEventHandle(false);
  }, []);
  const isSliderImages = useMemo(() => view === VIEW_PRODUCTS_GRID && images.length > 1, [images.length, view]);
  const withBadges = isBestseller || isKit || isNew;
  useEffect(() => {
    if (isLessMd) {
      onMouseLeaveHandle();
    }
  }, [isLessMd, onMouseLeaveHandle]);
  return <ProductWrapper style={{
    minHeight: isHover ? productRef?.current?.offsetHeight : undefined
  }} className={cx(isPreview && cssProductPreview)} availableStatus={availableStatus || undefined} onMouseEnter={onMouseEnterHandle} onMouseLeave={onMouseLeaveHandle} viewProductsVariant={view} isLoading={!isInitEntity} uuid={uuid || undefined} isFavorite={isFavorites} sortWeight={sortWeight} withBadges={withBadges} categories={categories} data-alias={alias} isHover={isHover} ref={productRef} {...props}>
        <ProductContainer>
          {!isMiniCard && <Badges isBestseller={isBestseller} isKit={isKit} isNew={isNew} />}

          <ButtonIconGroup isFetchingFavorites={isFetchingFavorites} isFetchingCompare={isFetchingCompare} toggleFavorite={toggleFavorite} toggleCompare={toggleCompare} isFavorites={isFavorites} isCompare={isCompare} typePage={typePage} />

          <ImageTitleCard isSliderImages={isSliderImages} name={name || ""} images={images} path={path} />

          <Title name={name || ""} article={article} code={code} path={path} />

          <Price dispBlock={isMiniCard ? true : false} unitMeasure={unitMeasure} currency={CURRENCY} price={priceUnit} />

          {isAvailable && !isMiniCard && <>
              {!isPreview && !isShared && <UnitControl unitValueActive={currentUnit || undefined} setCurrentUnit={updateCurrentUnit} isInitProduct={isInitEntity} unitMeasure={unitMeasure} totalQty={totalQty} units={units} />}
              <Counter isStatic={isShared && !inCart || isPreview} updateCountHandler={updateCurrentCount} initialUnit={+units[0]?.value ?? null} isCountError={isCountProductError} setIsCountError={setIsCountError} isInitProduct={isInitEntity} productIsRemove={isRemoved} currentCount={currentCount} currentUnit={currentUnit} unitMeasure={unitMeasure} isFetching={isFetching} productInCart={inCart} maxCount={totalQty} counter={counter} />
            </>}

          {!isPreview && <AddToCartControl inCart={inCart && !isRemoved} isAvailable={isAvailable} isFetching={isFetching} addToCart={addToCart} uuid={uuid || null} buttonAreaContent={isMiniCard ? "" : <Price price={priceCalculate} currency={CURRENCY} variant={"total"} />} />}

          {!isMiniCard && <>
              {isPreview && <Price messageImportant={isRemoved && isAvailable ? undefined : "нет в наличии"} price={isAvailable ? priceCalculate : 0} currency={CURRENCY} variant={"total"} />}

              <ListInfo storesQty={storesQty} stores={storesAvailable} />
              <Available status={availableStatus} />

              {isAvailable && <>
                  {!isLessMd && <ProductDetailInfo>
                      <CodeArticleInfo code={code} article={article} />
                    </ProductDetailInfo>}
                </>}
            </>}

          {isKit && <Modal title={"Добавлен в корзину"} variant={"rounded-70"} closeMode={"destroy"} isShowModal={isAdded}>
              <AfterAddedToCartKit kitProducts={kitProducts || []} companions={companions} product={product} analogs={analogs} onClose={() => {
          setIsAdded(false);
        }} />
            </Modal>}

          {kitParents.length > 0 && <Modal title={"Добавлен в корзину"} variant={"rounded-70"} isShowModal={isAdded} closeMode={"destroy"}>
              <AfterAddedToCartKit kitParents={kitParents || []} companions={companions} product={product} analogs={analogs} onClose={() => {
          setIsAdded(false);
        }} />
            </Modal>}
        </ProductContainer>
      </ProductWrapper>;
});
Product.displayName = "Product";
export { Product };
import { FC } from "react"
import { useQuery } from "react-query"
import { fetchProductsList } from "../../../../api/productsAPI"
import { getBreakpointVal } from "../../../../styles/utils/Utils"
import { breakpoints } from "../../../../styles/utils/vars"
import { BaseLoader } from "../../../ui/Loaders/BaseLoader/BaseLoader"
import { ProductsSlider } from "../../Slider/ProductsSlider"

export type KitPartProductsPropsType = {
  ids: string[]
  slidesPerViewStart?: number
}

export const KitPartProducts: FC<KitPartProductsPropsType> = ({
  ids,
  slidesPerViewStart = 4,
}) => {
  const { data, isFetching } = useQuery(["kitParentsProducts", ids], () =>
    ids.length > 0
      ? fetchProductsList({
          uuids: ids.join(","),
        })
      : null,
  )

  return (
    <>
      {isFetching && <BaseLoader />}
      <ProductsSlider
        products={data || []}
        variantArrow={"left-right"}
        responsiveExtends={{
          [getBreakpointVal(breakpoints.lg)]: {
            slidesPerView: slidesPerViewStart,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        disabledOnMobile
      />
    </>
  )
}

import type { FC } from "react"
import { memo, useState } from "react"
import Image, { ImageProps } from "next/image"
import { getImagePath } from "../../../utils/helpers"
import { BaseLoader } from "../Loaders/BaseLoader/BaseLoader"
import { NoImage } from "./NoImage"
import { StyledEntityImage } from "./Styled"

type ImagePropsType = Omit<ImageProps, "src" | "draggable"> & {
  imagePath?: string
  imageAlt?: string
  withPreloader?: boolean
  isStatic?: boolean
}
export const EntityImage: FC<ImagePropsType> = memo(
  ({
    imagePath,
    imageAlt = "",
    priority,
    width,
    height,
    withPreloader,
    isStatic = false,
    loading,
    ...props
  }) => {
    const [isLoading, setIsLoading] = useState(!!withPreloader)

    const src = isStatic ? imagePath || "" : getImagePath(imagePath || "")

    return (
      <StyledEntityImage {...props}>
        {!!imagePath ? (
          <>
            {isLoading && <BaseLoader />}
            <Image
              src={src}
              {...props}
              draggable={false}
              alt={imageAlt}
              priority={priority}
              width={width}
              height={height}
              loading={loading}
              onLoadingComplete={() => {
                setIsLoading(false)
              }}
              onError={() => {
                setIsLoading(false)
              }}
            />
          </>
        ) : (
          <NoImage />
        )}
      </StyledEntityImage>
    )
  },
)

EntityImage.displayName = "EntityImage"

import { FC, memo } from "react"
import dynamic, { DynamicOptions } from "next/dynamic"
import { Trigger } from "../../../../styles/utils/Utils"
import { ROUTES } from "../../../../utils/constants"
import { Modal } from "../../../ui/Modals/Modal"
import { Typography } from "../../../ui/Typography/Typography"
import type { KitPartProductsPropsType } from "./KitPartProducts"

const KitPartProducts = dynamic(
  (() =>
    import("./KitPartProducts").then(
      (mod) => mod.KitPartProducts,
    )) as DynamicOptions<KitPartProductsPropsType>,
  {
    ssr: false,
  },
)

export type KitPartPropsType = {
  kitParents: string[]
}

export const KitPart: FC<KitPartPropsType> = memo(({ kitParents }) => {
  const lengthKitParents = kitParents.length
  if (!lengthKitParents) {
    return null
  }

  return (
    <>
      {lengthKitParents > 1 ? (
        <Modal
          variant={"rounded-100"}
          closeMode={"destroy"}
          title={"Комплекты, в которые входит товар"}
          disclosure={
            <Typography variant={"p14"}>
              <Trigger>Является частью комплекта</Trigger>
            </Typography>
          }
        >
          <KitPartProducts ids={kitParents} />
        </Modal>
      ) : (
        <Typography>
          <a href={`${ROUTES.product}/${kitParents[0]}`}>
            Является частью комплекта
          </a>
        </Typography>
      )}
    </>
  )
})

KitPart.displayName = "KitPart"

import { colors } from "../../styles/utils/vars"
import { AvailableViewDataType } from "./types"

export const AVAILABLE_VIEW_DATA: AvailableViewDataType = {
  many: {
    icon: "StatsMany",
    label: "Много",
    color: colors.brand.green,
  },
  enough: {
    icon: "StatsMany",
    label: "Достаточно",
    color: colors.brand.green,
  },
  few: {
    icon: "StatsFew",
    label: "Мало",
    color: colors.brand.yellow,
  },
  not: {
    icon: "StatsNot",
    label: "Нет в наличии",
    color: colors.red,
  },
}

import React, { FC, memo } from "react";
import { Link } from "../../../ui/Link";
import { cssProductTitle } from "../../StyledProduct";
export const Title: FC<{
  name: string | null;
  path: string | null;
  code?: string | null | undefined;
  article?: string | null | undefined;
}> = memo(({
  article,
  name,
  path,
  code
}) => {
  const fullString = !!name ? `${name}. ${!!code ? `Код: ${code}.` : ""} ${!!article ? `Артикул: ${article}` : ""}` : "";
  return <Link href={path || ""} scroll={true} shallow={false} aria-label={name || ""} title={fullString} className={cssProductTitle}>
      {name}
    </Link>;
});
Title.displayName = "Title";
import { ChangeEvent, FC, FocusEvent, memo, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { cx } from "@linaria/core";
import { useDebounce } from "../../../../hooks/debounce";
import { UnitMeasureType } from "../../../../types/types";
import { Button } from "../../../ui/Button/Button";
import { StyledInput } from "../../../ui/Field/StyledField";
import { ComponentLoader } from "../../../ui/Loaders/ComponentLoader/ComponentLoader";
import { Typography } from "../../../ui/Typography/Typography";
import { Unit } from "../Price";
import { ErrorPropsType } from "./Error";
import { CounterInput, cssIsDisabled, minus, plus, StyledCount, StyledCounter } from "./StyledCounter";
const Error = dynamic(((() => import("./Error").then(mod => mod.Error)) as DynamicOptions<ErrorPropsType>), {
  ssr: false
});
export const MACROS_ERROR_COUNT = "#count#";
const getCounterMsg = (message: string, replace?: string): string => message.replace(MACROS_ERROR_COUNT, `<NoWrap>${replace}</NoWrap>`);
type CounterPropsType = {
  currentCount: number | null;
  currentUnit: number | null;
  initialUnit: number | null;
  unitMeasure?: UnitMeasureType | null;
  isStatic?: boolean;
  maxCount?: number;
  isFetching?: boolean;
  isHideMinus?: boolean;
  productInCart?: boolean;
  productIsRemove?: boolean;
  updateCountHandler?: (count: number) => void;
  counter?: number | null;
  isCountError?: boolean;
  setIsCountError?: (isError: boolean) => void;
  isInitProduct?: boolean;
  messageError?: string;
};
interface IgetValueFX {
  count: number;
  currentUnit: number;
  initialUnit: number | null;
  maxCount: number;
}
const getValue = ({
  currentUnit,
  initialUnit,
  maxCount,
  count
}: IgetValueFX): number => {
  // если переключились на увелечение по коробкам, прибавляем продукт по количеству в коробке.
  //но если 2 коробки превышают остаток, прибавляем количество по количеству в пачке
  let value = count + currentUnit;
  if (initialUnit !== currentUnit && count + currentUnit > maxCount) {
    if (count === null || initialUnit === null) return value;
    value = count + initialUnit;
  }
  return value;
};
const CounterInner: FC<CounterPropsType> = memo(({
  unitMeasure = "шт.",
  isStatic = false,
  maxCount = 0,
  updateCountHandler,
  isFetching,
  isHideMinus,
  productInCart,
  productIsRemove,
  counter = null,
  currentUnit = null,
  initialUnit,
  currentCount,
  isCountError,
  setIsCountError,
  isInitProduct,
  messageError
}) => {
  const [isDisabledPlus, setIsDisabledPlus] = useState(false);
  const [count, setCount] = useState<number | null>(counter || null);
  const [isShowErrorPopup, setIsShowErrorPopup] = useState<boolean>(false);
  const debouncedCount = useDebounce(count, 750);
  const isDisabledMinus = useMemo(() => currentUnit === null || (currentUnit === currentCount || maxCount < currentUnit) && (!productInCart || productIsRemove) || (count || 0) < 1, [count, currentCount, currentUnit, maxCount, productInCart, productIsRemove]);
  const onChangeHandle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (currentUnit) {
      const value = parseInt(event.currentTarget.value);
      setCount(!!value ? value : 0);
    }
  }, [currentUnit]);
  const onFocusHandle = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.currentTarget.select();
  }, []);
  const countMinus = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (count === null || count < 1 || currentUnit === null) {
      return;
    }
    setCount(count - currentUnit);
  };
  const countPlus = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (count === null || currentUnit === null) return;
    const value: number = getValue({
      currentUnit,
      initialUnit,
      maxCount,
      count
    });
    if (value > maxCount) {
      setIsDisabledPlus(true);
      if (setIsCountError) {
        setIsCountError(true);
      }
      return;
    }
    setCount(value);
  };
  const countVisible = useMemo(() => count === null || count < 0 ? 0 : count, [count]);
  const msgErr = useMemo(() => !!messageError ? `${getCounterMsg(messageError)}` : `${getCounterMsg(`Доступно только ${MACROS_ERROR_COUNT} этого товара`, `${maxCount}&nbsp;${unitMeasure}`)}`, [maxCount, messageError, unitMeasure]);
  useEffect(() => {
    setIsDisabledPlus(count === null || currentUnit === null || getValue({
      currentUnit,
      initialUnit,
      maxCount,
      count
    }) > maxCount);
  }, [count, currentCount, currentUnit, initialUnit, maxCount]);
  useEffect(() => {
    return () => {
      setCount(null);
      setIsShowErrorPopup(false);
    };
  }, []);
  useEffect(() => {
    if (currentCount !== null) {
      setCount(currentCount);
    }
  }, [currentCount, counter]);
  useEffect(() => {
    if (debouncedCount === null || !updateCountHandler || !isInitProduct) {
      return;
    }
    updateCountHandler(debouncedCount);
  }, [debouncedCount, updateCountHandler, isInitProduct]);
  useEffect(() => {
    if (isCountError !== undefined) {
      setTimeout(() => {
        setIsShowErrorPopup(isCountError);
      }, 100);
    }
  }, [isCountError]);
  return <>
        <StyledCounter className={cx(!maxCount && cssIsDisabled)}>
          {isFetching && <ComponentLoader />}

          {!!isCountError && <Error message={msgErr} isShow={isShowErrorPopup} />}

          {!isStatic ? <>
              {!isHideMinus && <Button variant={"link"} className={cx(minus)} icon={"Minus"} onClick={countMinus} isHiddenBg disabled={isDisabledMinus} aria-label={"Уменьшить количество товара"} seoText={"Уменьшить количество товара"} role={"button"} type={"button"} />}
              <CounterInput>
                <StyledInput type="text" inputMode={"numeric"} value={countVisible} onChange={onChangeHandle} onFocus={onFocusHandle} aria-label={"Количество товара"} />
                <Unit>{unitMeasure}</Unit>
              </CounterInput>
              <Button variant={"link"} className={cx(plus)} icon={"Plus"} onClick={countPlus} isHiddenBg disabled={isDisabledPlus} aria-label={"Увеличить количество товара"} seoText={"Увеличить количество товара"} role={"button"} type={"button"} />
            </> : <StyledCount>
              <Typography variant={"p12"}>{count}</Typography>
              <Unit>{unitMeasure}</Unit>
            </StyledCount>}
        </StyledCounter>
      </>;
});
export const Counter: FC<CounterPropsType> = ({
  isInitProduct,
  ...rest
}) => {
  const [isInit, setIsInit] = useState(!!isInitProduct);
  useEffect(() => {
    setIsInit(!!isInitProduct);
    return () => {
      setIsInit(false);
    };
  }, [isInitProduct]);
  return <>{isInit && <CounterInner {...rest} isInitProduct={isInit} />}</>;
};
CounterInner.displayName = "CounterInner";
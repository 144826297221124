import { FC, useCallback, useEffect, useRef, useState } from "react";
import Image from "next/image";
import { cx } from "@linaria/core";
import { EffectFade, FreeMode, Keyboard, Navigation, Thumbs } from "swiper";

// import required modules
import { SwiperSlide } from "swiper/react";
import { Swiper } from "swiper/types";
import { useWindowSize } from "../../../../hooks/windowSize";
import { getBreakpointVal } from "../../../../styles/utils/Utils";
import { breakpoints } from "../../../../styles/utils/vars";
import { cssNavIsDisabled, cssNavNext, cssNavPrev } from "../../../Swiper/StyledSwiper";
import { navigationDestroy, navigationInit, NavigationInitPropsType, SwiperWrapper } from "../../../Swiper/SwiperWrapper";
import { Button } from "../../../ui/Button/Button";
import { EntityImage } from "../../../ui/EntityImage/EntityImage";
import { cssSliderThumbs, StyledGalleryMainImage, StyledGallerySlider, StyledThumb } from "./Styled";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
export type GallerySliderPropsType = {
  images: string[];
};
export const GallerySlider: FC<GallerySliderPropsType> = ({
  images
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<null | Swiper>(null);
  const [mainSwiper, setMainSwiper] = useState<null | Swiper>(null);
  const {
    width
  } = useWindowSize();
  const [navMainIsDisabled, setNavMainIsDisabled] = useState(false);
  const prevMainRef = useRef<HTMLButtonElement>(null);
  const nextMainRef = useRef<HTMLButtonElement>(null);
  const isResponsive = width !== undefined && width <= getBreakpointVal(breakpoints.md);
  const init = useCallback(({
    swiper,
    prevEl,
    nextEl,
    cbDestroy
  }: NavigationInitPropsType & {
    cbDestroy?: () => void;
    cbInit?: () => void;
  }) => {
    if (isResponsive) {
      navigationDestroy({
        swiper
      });
      if (!!cbDestroy) {
        cbDestroy();
      }
    } else {
      navigationInit({
        swiper,
        prevEl: prevEl,
        nextEl: nextEl
      });
    }
  }, [isResponsive]);
  useEffect(() => {
    return () => {
      thumbsSwiper?.destroy();
      mainSwiper?.destroy();
    };
  }, [mainSwiper, thumbsSwiper]);
  if (images.length <= 0 && width === undefined) {
    return null;
  }
  return <StyledGallerySlider>
      <SwiperWrapper onInit={swiper => {
      setMainSwiper(swiper);
      init({
        swiper,
        prevEl: prevMainRef.current || undefined,
        nextEl: nextMainRef.current || undefined,
        cbDestroy: () => {
          setNavMainIsDisabled(true);
        },
        cbInit: () => {
          setNavMainIsDisabled(false);
        }
      });
    }} slidesPerView={1} spaceBetween={0} thumbs={{
      swiper: thumbsSwiper
    }} modules={[Keyboard, Navigation, EffectFade, Thumbs]} effect={"fade"} keyboard={{
      enabled: true
    }} speed={100} className={cx(navMainIsDisabled && cssNavIsDisabled)} updateOnWindowResize={true}>
        {images.map((i, index) => <SwiperSlide key={index}>
            <StyledGalleryMainImage>
              <EntityImage imagePath={i} alt={""} objectFit={"contain"} quality={60} priority={false} loading={"lazy"} layout={"fill"} withPreloader={true} />
            </StyledGalleryMainImage>
          </SwiperSlide>)}
        {!navMainIsDisabled && <>
            <Button variant={"arrow"} icon={"AngleLeft"} className={cssNavPrev} ref={prevMainRef} />
            <Button variant={"arrow"} icon={"AngleRight"} className={cssNavNext} ref={nextMainRef} />
          </>}
      </SwiperWrapper>

      <SwiperWrapper onInit={swiper => {
      setThumbsSwiper(swiper);
    }} spaceBetween={10} modules={[Keyboard, Navigation, FreeMode, Thumbs]} slidesPerView={"auto"} breakpoints={{
      0: {
        slidesPerView: "auto"
      }
    }} keyboard={{
      enabled: true
    }} freeMode={true} watchSlidesProgress={true} className={cx(cssSliderThumbs)} updateOnWindowResize={true} centerInsufficientSlides={true}>
        {images.map((i, index) => <SwiperSlide key={index}>
            <StyledThumb>
              <Image src={`https://${i}`} loading={"lazy"} layout={"fixed"} height={80} width={80} alt={""} />
            </StyledThumb>
          </SwiperSlide>)}
      </SwiperWrapper>
    </StyledGallerySlider>;
};
import { FC, memo } from "react";
import { cx } from "@linaria/core";
import { Button } from "../../../ui/Button/Button";
import { cssButtonComparison, cssButtonAddFavorite, cssButtonIcon } from "../../../ui/Button/StyledButton";
import { ButtonVariantsPropsType } from "../../../ui/Button/types";
import { IconNameType } from "../../../ui/Icon";
const typeText = {
  favorite: {
    ariaLabel: "Добавить в избранное",
    seoText: "Добавить в избранное",
    title: "Добавить в избранное",
    icon: {
      notActive: ("StarOutline" as IconNameType),
      active: ("Star" as IconNameType)
    },
    style: cssButtonAddFavorite
  },
  compare: {
    ariaLabel: "Добавить в сравнение",
    seoText: "Добавить в сравнение",
    title: "Добавить в сравнение",
    icon: {
      active: ("CompareActive" as IconNameType),
      notActive: ("CompareAdd" as IconNameType)
    },
    style: cssButtonComparison
  },
  delete: {
    ariaLabel: "удалить",
    seoText: "удалить",
    title: "удалить",
    icon: {
      active: ("Delete" as IconNameType),
      notActive: ("Delete" as IconNameType)
    },
    style: cssButtonComparison
  }
};
export const ButtonToggleIcon: FC<{
  typeBtn?: "favorite" | "compare" | "delete";
  isFetching?: boolean;
  toggle: () => void;
  isActive: boolean;
} & Pick<ButtonVariantsPropsType, "size" | "variant" | "isClean">> = memo(({
  typeBtn = "favorite",
  isFetching = false,
  isActive,
  isClean,
  variant,
  toggle,
  size
}) => {
  return <Button icon={isActive ? typeText[typeBtn].icon.active : typeText[typeBtn].icon.notActive} aria-label={typeText[typeBtn].ariaLabel} className={cx(cssButtonIcon, typeText[typeBtn].style)} variant={!!variant ? variant : "link"} seoText={typeText[typeBtn].seoText} title={typeText[typeBtn].title} data-isfavorite={isActive} isFetching={isFetching} isClean={isClean} onClick={toggle} role={"button"} type={"button"} size={size} />;
});
ButtonToggleIcon.displayName = "ButtonToggleIcon";
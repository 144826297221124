import { MutableRefObject, useEffect, useRef } from "react"

export const usePrevious: <T>(
  props: T | undefined,
) => MutableRefObject<T | undefined>["current"] = (value) => {
  const ref = useRef<any>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

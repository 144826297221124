import { FC } from "react"
import { EntityStoreType } from "../../../hooks/product/types"
import { dateToString } from "../../../utils/helpers"
import { StyledSchedule } from "../Shop/StyledShop"
import { Available } from "components/Available"

type StorePropsType = Pick<EntityStoreType, "deliveryDate" | "availableStatus">

const Store: FC<StorePropsType> = ({ availableStatus, deliveryDate }) => {
  return (
    <>
      {!!deliveryDate && (
        <StyledSchedule>
          Ближайшая дата доставки {dateToString(deliveryDate)}
        </StyledSchedule>
      )}

      <Available status={availableStatus || null} />
    </>
  )
}

export type { StorePropsType }

export { Store }

import { IshareInMessages } from "./types"

export const shareOnWhatsapp = (copiText: string): void => {
  window.open(
    `https://api.whatsapp.com/send?text=${encodeURIComponent(copiText)}`,
  )
}

export const shareOnTelegram = (copiText: string): void => {
  window.open(
    `https://telegram.me/share/url?url=${encodeURIComponent(copiText)}`,
  )
}

export const shareOnViber = (copiText: string): void => {
  window.open(`viber://forward?text=${encodeURIComponent(copiText)}`)
}

export const shareOnMail = (copiText: string): void => {
  window.open(`mailto:?subject=&body=${encodeURIComponent(copiText)}`)
}

export const shareOnInstagram = (copiText: string): void => {
  window.open(
    `https://www.instagram.com/share?url=${encodeURIComponent(copiText)}`,
  )
}

export const shareOnVk = (copiText: string): void => {
  window.open(`https://vk.com/share.php?url=${encodeURIComponent(copiText)}`)
}

export const shareOnTwitter = (copiText: string): void => {
  window.open(
    `https://twitter.com/intent/tweet?url=${encodeURIComponent(copiText)}`,
  )
}

export const shareOnFacebook = (copiText: string): void => {
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      copiText,
    )}`,
  )
}

export const shareInMessages = ({ title, url, text }: IshareInMessages) => {
  if (!navigator || typeof navigator === undefined || !navigator?.share) {
    return
  }
  navigator
    .share({
      title: title,
      url: url,
      text: text,
    })
    .catch((err) => {
      console.log("Shared error: ", err)
    })
}

import { FC, memo } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { cx } from "@linaria/core";
import { useWindowSize } from "../../../../hooks/windowSize";
import { getBreakpointVal } from "../../../../styles/utils/Utils";
import { breakpoints } from "../../../../styles/utils/vars";
import { Button } from "../../../ui/Button/Button";
import { EntityImage } from "../../../ui/EntityImage/EntityImage";
import { Modal } from "../../../ui/Modals/Modal";
import { IMAGE_CONFIG } from "../../constants";
import { ImageTile, PreviewSlider } from "../../parts";
import { cssZoomIn } from "../../StyledProduct";
import { IButtonIconGroup } from "./types";
import { IMAGE_TITLE_CARD_SIZE_MODIFICATOR } from "../../../../utils/constants";
const GallerySlider = dynamic(((() => import("../../parts").then(mod => mod.GallerySlider)) as DynamicOptions<{
  images: string[];
}>), {
  ssr: false
});
export const ImageTitleCard: FC<IButtonIconGroup> = memo(({
  isSliderImages,
  images,
  name,
  path
}) => {
  const {
    width
  } = useWindowSize();
  const isLessMd = width !== undefined && width <= getBreakpointVal(breakpoints.md);
  const isLessSm = width !== undefined && width <= getBreakpointVal(breakpoints.sm);
  return <ImageTile path={path} isSlider={isSliderImages}>
        {isSliderImages ? <PreviewSlider alt={name || ""} images={images} url={path} layout={"intrinsic"} width={isLessSm ? IMAGE_CONFIG.responsive.size.width : IMAGE_CONFIG.grid.size.width - IMAGE_TITLE_CARD_SIZE_MODIFICATOR} height={isLessSm ? IMAGE_CONFIG.responsive.size.height : IMAGE_CONFIG.grid.size.width - IMAGE_TITLE_CARD_SIZE_MODIFICATOR} objectFit={"contain"} quality={isLessMd ? IMAGE_CONFIG.grid.quality : IMAGE_CONFIG.grid.quality} /> : <EntityImage imagePath={images.length > 0 ? images[0] : undefined} imageAlt={name || ""} layout={"intrinsic"} width={isLessMd ? IMAGE_CONFIG.responsive.size.width : IMAGE_CONFIG.grid.size.width - IMAGE_TITLE_CARD_SIZE_MODIFICATOR} height={isLessMd ? IMAGE_CONFIG.responsive.size.width : IMAGE_CONFIG.grid.size.width - IMAGE_TITLE_CARD_SIZE_MODIFICATOR} objectFit={"contain"} quality={isLessMd ? IMAGE_CONFIG.responsive.quality : IMAGE_CONFIG.grid.quality} />}

        {images.length > 0 && <Modal closeMode={"destroy"} variant={"full"} disclosure={<Button icon={"ZoomIn"} variant={"box"} className={cx(cssZoomIn)} aria-label={"Увеличить изображение"} seoText={"Увеличить изображение"} />}>
            <GallerySlider images={images} />
          </Modal>}
      </ImageTile>;
});
ImageTitleCard.displayName = "ImageTitleCard";
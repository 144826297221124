import type { BaseHTMLAttributes, FC } from "react";
import { cx } from "@linaria/core";
import { Link } from "../../../ui/Link";
import { cssIsSlider, StyledImageTile } from "./StyledImageTile";
export type ImageTilePropsType = {
  path: string | null;
  isSlider?: boolean;
};
export const ImageTile: FC<ImageTilePropsType & BaseHTMLAttributes<HTMLDivElement>> = ({
  children,
  path,
  isSlider,
  className,
  ...props
}) => {
  return <StyledImageTile {...props} className={cx(!!isSlider && cssIsSlider, className)}>
      {!isSlider && <Link href={path || ""} scroll />}
      {children}
    </StyledImageTile>;
};
import { BaseHTMLAttributes, FC, memo } from "react";
import { StyledBadge, StyledName, StyledBadges, cssIsBestseller, cssIsKit, cssIsNew } from "./Styled";
const Badge: FC<{
  title: string;
} & BaseHTMLAttributes<HTMLDivElement>> = ({
  title,
  ...props
}) => {
  return <StyledBadge {...props}>
      <StyledName>{title}</StyledName>
    </StyledBadge>;
};
export const Badges: FC<{
  isKit?: boolean;
  isBestseller?: boolean;
  isNew?: boolean;
}> = memo(({
  isKit,
  isBestseller,
  isNew
}) => {
  return <>
      {(isKit || isBestseller || isNew) && <StyledBadges>
          {isBestseller && <Badge title={"Хит"} className={cssIsBestseller} />}
          {isKit && <Badge title={"Комплект"} className={cssIsKit} />}
          {isNew && <Badge title={"Новинка"} className={cssIsNew} />}
        </StyledBadges>}
    </>;
});
Badges.displayName = "Badges";
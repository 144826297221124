import { BaseHTMLAttributes, forwardRef } from "react";
import { cx } from "@linaria/core";
import { VIEW_PRODUCTS_GRID } from "../../utils/constants";
import { SkeletonProduct } from "./parts/Skeleton/Skeleton";
import { cssProductAnimate, cssWithBadges, LayoutProductPropsType, StyledProductTag } from "./StyledProduct";
export type ProductWrapperPropsType = LayoutProductPropsType & BaseHTMLAttributes<HTMLDivElement> & {
  isLoading?: boolean;
};
export const ProductWrapper = forwardRef<HTMLDivElement, ProductWrapperPropsType>(({
  uuid,
  isHover = false,
  isFavorite,
  viewProductsVariant = VIEW_PRODUCTS_GRID,
  availableStatus,
  isKit,
  isRemoved,
  children,
  isAnimate,
  className,
  sortWeight,
  isAvailable,
  categories,
  isLoading = false,
  withBadges,
  modifier,
  ...props
}, ref) => {
  return <StyledProductTag className={cx(isAnimate && cssProductAnimate, withBadges && cssWithBadges, className)} data-available-status={availableStatus} data-view={viewProductsVariant} data-is-available={isAvailable} data-sort-weight={sortWeight} data-isfavorite={isFavorite} data-categories={categories} data-is-removed={isRemoved} data-modifier={modifier} data-is-hover={isHover} data-is-kit={isKit} data-id={uuid} {...props} ref={ref}>
        {isLoading && <SkeletonProduct />}
        {children}
      </StyledProductTag>;
});
ProductWrapper.displayName = "ProductWrapper";
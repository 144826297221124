import type { FC } from "react";
import { memo } from "react";
import { cx } from "@linaria/core";
import { declinationOfNum, getNumberOfDays } from "../../../../utils/helpers";
import { Button } from "../../../ui/Button/Button";
import { cssButtonClean, cssButtonPulse } from "../../../ui/Button/StyledButton";
import { Popover } from "../../../ui/Popover/Popover";
import { Typography } from "../../../ui/Typography/Typography";
import { cssTooltipDate } from "../../StyledProduct";
export type NoticeDiffDeliveryDatePropsType = {
  onFindAnalogHandle?: () => void;
  shippingDate: string | null;
  minShippingDateCart: string | null;
  isAnalogs: boolean;
};
export const NoticeDiffDeliveryDate: FC<NoticeDiffDeliveryDatePropsType> = memo(({
  shippingDate,
  minShippingDateCart,
  onFindAnalogHandle,
  isAnalogs
}) => {
  const diffDay = shippingDate !== null && minShippingDateCart !== null ? getNumberOfDays(minShippingDateCart, shippingDate) : 0;
  if (!diffDay) {
    return <></>;
  }
  return <Popover placement={"bottom-start"} withHover isStylingIconDisclosure={false} offset={[0, 0]} disclosure={<Button variant={"box"} icon={"Warning"} className={cx(cssButtonClean, cssButtonPulse)} />} className={cssTooltipDate}>
        <Typography>
          Этого товара нет на складе быстрой доставки. Для оформления быстрой
          доставки можете подобрать аналоги
          <br />
          <b>
            Cрок доставки увеличится на {diffDay}&nbsp;
            {declinationOfNum(diffDay, ["день", "дня", "дней"])}
          </b>
        </Typography>
        {isAnalogs && <Button variant={"filled"} size={"small"} isHiddenBg onClick={() => {
      if (onFindAnalogHandle) {
        onFindAnalogHandle();
      }
    }}>
            Выберите аналоги
          </Button>}
      </Popover>;
});
NoticeDiffDeliveryDate.displayName = "NoticeDiffDeliveryDate";
import { FC, memo, useEffect, useMemo, useState } from "react";
import dynamic, { DynamicOptions } from "next/dynamic";
import { EntityStoreType } from "../../../../hooks/product/types";
import { useAppSelector } from "../../../../hooks/redux";
import { ShopType } from "../../../../hooks/shops/types";
import { Trigger } from "../../../../styles/utils/Utils";
import { declinationOfNum } from "../../../../utils/helpers";
import { StyledList, StyledListItem } from "../../../List/StyledList";
import { Store } from "../../../Shops/Store";
import { ExtendElementsType, ListMapPropsType } from "../../../Shops/types";
import { Modal } from "../../../ui/Modals/Modal";
import { Span, Typography } from "../../../ui/Typography/Typography";
import { cssStoreAvailable } from "../../StyledProduct";
import { StyledStoresAvailable } from "./StyledStoresAvailable";
const ListMap = dynamic(((() => import("../../../Shops/ListMap").then(mod => mod.ListMap)) as DynamicOptions<ListMapPropsType>), {
  ssr: false
});
type StoresAvailablePropsType = {
  storesQty: number;
  stores?: EntityStoreType[];
};
const StoresAvailable: FC<StoresAvailablePropsType> = memo(({
  storesQty,
  stores,
  children
}) => {
  const text = useMemo(() => declinationOfNum(storesQty, ["складе", "складах", "складах"]), [storesQty]);
  const {
    shops
  } = useAppSelector(({
    shop
  }) => shop);
  const [shopsFormatted, setShopsFormatted] = useState<ShopType[]>([]);
  const [shopsExtends, setShopsExtends] = useState<ExtendElementsType | undefined>(undefined);
  useEffect(() => {
    let arr: ShopType[] = [];
    const ext: ExtendElementsType = {};
    if (!!stores && !!shops) {
      for (const st of stores) {
        arr = [...arr, {
          ...(shops[st.uuid] || {}),
          address: st.name
        }];
        ext[st.uuid] = {
          element: () => <Store availableStatus={st.availableStatus} deliveryDate={st.deliveryDate} />
        };
      }
    }
    setShopsFormatted(arr);
    setShopsExtends(Object.keys(ext).length > 0 ? ext : undefined);
  }, [shops, stores]);
  if (!storesQty) {
    return null;
  }
  return <StyledStoresAvailable>
        <StyledList>
          <StyledListItem>
            <Typography className={cssStoreAvailable}>
              <Span>В наличии: </Span>
              <Modal title={"Пункты самовывоза на карте"} closeMode={"destroy"} variant={"rounded-100"} disclosure={<Trigger>
                    на&nbsp;{storesQty}&nbsp;{text}
                  </Trigger>}>
                <ListMap shops={shopsFormatted} extendElements={shopsExtends} />
              </Modal>
            </Typography>
          </StyledListItem>
        </StyledList>
        {children}
      </StyledStoresAvailable>;
});
StoresAvailable.displayName = "StoresAvailable";
export type { StoresAvailablePropsType };
export { StoresAvailable };
import { FC } from "react"
import { Typography } from "../../../ui/Typography/Typography"
import { StyledCodArticleInfo } from "./StyledCodArticleInfo"

interface ICodeArticleInfo {
  code?: string
  article?: string
}

export const CodeArticleInfo: FC<ICodeArticleInfo> = ({ code, article }) => {
  return (
    <StyledCodArticleInfo>
      {!!code && <Typography variant={"p12"}>Код: {code}</Typography>}
      {!!article && <Typography variant={"p12"}>Арт.: {article}</Typography>}
    </StyledCodArticleInfo>
  )
}

CodeArticleInfo.displayName = "CodeArticleInfo"

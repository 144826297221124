import { FC, memo } from "react"
import { dateToString } from "../../../../utils/helpers"
import { Typography } from "../../../ui/Typography/Typography"
import { StyledDeliveryMessage } from "../../StyledProduct"

export const DeliveryMessage: FC<{ deliveryDate: Date | null }> = memo(
  ({ deliveryDate }) => {
    if (!deliveryDate) {
      return null
    }
    return (
      <StyledDeliveryMessage>
        Срок доставки:{" "}
        <Typography variant={"span"}>
          {dateToString(deliveryDate, false)}
        </Typography>
      </StyledDeliveryMessage>
    )
  },
)

DeliveryMessage.displayName = "DeliveryMessage"

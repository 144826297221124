import { FC, memo } from "react"
import { ButtonToggleIcon } from "../../parts"
import { StyledFloatControls, StyleGroupRowButton } from "../../StyledProduct"
import { IButtonIconGroup } from "./types"

export const ButtonIconGroup: FC<IButtonIconGroup> = memo(
  ({
    isFetchingFavorites,
    isFetchingCompare,
    toggleFavorite,
    toggleCompare,
    isFavorites,
    isCompare,
    typePage,
  }) => {
    const isHideBtnDelete =
      (typePage === "favorite" && isFavorites) ||
      (typePage === "compare" && isCompare)

    const constDeleteBtn = {
      favorite: {
        toggle: toggleFavorite,
        isActive: isFavorites,
      },
      compare: {
        toggle: toggleCompare,
        isActive: isCompare,
      },
    }

    return (
      <StyledFloatControls>
        <StyleGroupRowButton>
          {typePage !== "compare" && (
            <ButtonToggleIcon
              isFetching={isFetchingFavorites}
              toggle={toggleFavorite}
              isActive={isFavorites}
              typeBtn={"favorite"}
            />
          )}
          {typePage !== "favorite" && (
            <ButtonToggleIcon
              isFetching={isFetchingCompare}
              toggle={toggleCompare}
              isActive={isCompare}
              typeBtn={"compare"}
            />
          )}
          {typePage !== "any" && isHideBtnDelete && (
            <ButtonToggleIcon
              isActive={constDeleteBtn[typePage].isActive}
              toggle={constDeleteBtn[typePage].toggle}
              typeBtn={"delete"}
            />
          )}
        </StyleGroupRowButton>
      </StyledFloatControls>
    )
  },
)

ButtonIconGroup.displayName = "ButtonIconGroup"

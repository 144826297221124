import { FC } from "react"
import { Icon, IconNameType } from "../ui/Icon"
import { Typography } from "../ui/Typography/Typography"

const Layout: FC<{
  title: string
  iconColor: string
  iconName: IconNameType
  visibleIndicator: boolean
}> = ({ iconColor, visibleIndicator, title, iconName }) => {
  return (
    <>
      <Icon NameIcon={iconName} />
      {visibleIndicator && <Icon NameIcon={"Car"} fill={iconColor} />}
      <Typography variant={"p12"}>{title}</Typography>
    </>
  )
}

export { Layout }
